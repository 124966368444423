:root {
    --color-default: #38312d;

    --color-primary: #005D9E;
    --color-primary-dark: #004f83;

    --color-arosa: #005D9E;
    --color-lenzerheide: #F2213B;

    --bg-lightgrey: #dddbda;

    --color-lightgrey: #ada8a6;


    /*!**** Fonts ****!*/

    --font-default-family: marine, sans-serif;
    --font-light-family: marine, sans-serif;
    --font-bold-family: marine, sans-serif;

    --font-lobster-family: 'Lobster-Reg',sans-serif;

    --font-themix-family: 'TheMix', sans-serif;
    --font-themix-bold: 'TheMix-Bld', sans-serif;

    /* same font because of switch to typekit - font weights for distinguishing are 700 for bold  & 400 for regular */
    --font-larken-default: 'larken',serif;
    --font-larken-bold: 'larken',serif;
}