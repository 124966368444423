@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1742368163825/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1742368163825/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1742368163825/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                    -webkit-transform: rotate(270deg);
                    -ms-transform: rotate(270deg);
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
                    -webkit-transform: scale(-1, 1);
                    -ms-transform: scale(-1, 1);
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
                    -webkit-transform: scale(1, -1);
                    -ms-transform: scale(1, -1);
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-add:before { content: "\EA01" }
.icon-anl-3er-sessellift:before { content: "\EA02" }
.icon-anl-4er-sessellift:before { content: "\EA03" }
.icon-anl-6er-sessellift:before { content: "\EA04" }
.icon-anl-8er-sessellift:before { content: "\EA05" }
.icon-anl-bahn:before { content: "\EA06" }
.icon-anl-foerderband:before { content: "\EA07" }
.icon-anl-gondel:before { content: "\EA08" }
.icon-anl-kinderlift:before { content: "\EA09" }
.icon-anl-schlepplift:before { content: "\EA0A" }
.icon-anl-sessellift:before { content: "\EA0B" }
.icon-anlagen:before { content: "\EA0C" }
.icon-arrow-back:before { content: "\EA0D" }
.icon-arrow-down:before { content: "\EA0E" }
.icon-arrow-dropdown:before { content: "\EA0F" }
.icon-arrow-left:before { content: "\EA10" }
.icon-arrow-next:before { content: "\EA11" }
.icon-arrow-right-portal:before { content: "\EA12" }
.icon-arrow-right:before { content: "\EA13" }
.icon-bahn:before { content: "\EA14" }
.icon-bb-ticket:before { content: "\EA15" }
.icon-bear:before { content: "\EA16" }
.icon-bed:before { content: "\EA17" }
.icon-berg-2:before { content: "\EA18" }
.icon-calendar-check:before { content: "\EA19" }
.icon-calendar-date:before { content: "\EA1A" }
.icon-calendar:before { content: "\EA1B" }
.icon-checkmark:before { content: "\EA1C" }
.icon-clock:before { content: "\EA1D" }
.icon-close:before { content: "\EA1E" }
.icon-closed:before { content: "\EA1F" }
.icon-cross:before { content: "\EA20" }
.icon-danger:before { content: "\EA21" }
.icon-depot:before { content: "\EA22" }
.icon-elements:before { content: "\EA23" }
.icon-external-link:before { content: "\EA24" }
.icon-facebook:before { content: "\EA25" }
.icon-folder:before { content: "\EA26" }
.icon-fullscreen:before { content: "\EA27" }
.icon-geschenk:before { content: "\EA28" }
.icon-gondel:before { content: "\EA29" }
.icon-gplus:before { content: "\EA2A" }
.icon-guiding:before { content: "\EA2B" }
.icon-gutschein:before { content: "\EA2C" }
.icon-home:before { content: "\EA2D" }
.icon-in-vorbereitung:before { content: "\EA2E" }
.icon-information:before { content: "\EA2F" }
.icon-instagram:before { content: "\EA30" }
.icon-kinderland:before { content: "\EA31" }
.icon-kulinarik-2:before { content: "\EA32" }
.icon-language:before { content: "\EA33" }
.icon-linkedin:before { content: "\EA34" }
.icon-locate:before { content: "\EA35" }
.icon-location:before { content: "\EA36" }
.icon-mail:before { content: "\EA37" }
.icon-map-poi:before { content: "\EA38" }
.icon-map:before { content: "\EA39" }
.icon-menu:before { content: "\EA3A" }
.icon-minus:before { content: "\EA3B" }
.icon-money:before { content: "\EA3C" }
.icon-network-cam:before { content: "\EA3D" }
.icon-open:before { content: "\EA3E" }
.icon-opened:before { content: "\EA3F" }
.icon-overview:before { content: "\EA40" }
.icon-package:before { content: "\EA41" }
.icon-panorama-cam:before { content: "\EA42" }
.icon-pause:before { content: "\EA43" }
.icon-personen:before { content: "\EA44" }
.icon-phone:before { content: "\EA45" }
.icon-plus:before { content: "\EA46" }
.icon-poi-map:before { content: "\EA47" }
.icon-poi:before { content: "\EA48" }
.icon-quote:before { content: "\EA49" }
.icon-restaurant-952:before { content: "\EA4A" }
.icon-schnee:before { content: "\EA4B" }
.icon-shopping-cart:before { content: "\EA4C" }
.icon-snowpark:before { content: "\EA4D" }
.icon-sort-down:before { content: "\EA4E" }
.icon-sort-up:before { content: "\EA4F" }
.icon-speed:before { content: "\EA50" }
.icon-sportbericht:before { content: "\EA51" }
.icon-spotify:before { content: "\EA52" }
.icon-star:before { content: "\EA53" }
.icon-suche:before { content: "\EA54" }
.icon-ticket:before { content: "\EA55" }
.icon-ticket2:before { content: "\EA56" }
.icon-tickets:before { content: "\EA57" }
.icon-tik-tok:before { content: "\EA58" }
.icon-timer:before { content: "\EA59" }
.icon-tvchannel:before { content: "\EA5A" }
.icon-twitter:before { content: "\EA5B" }
.icon-unterkunft:before { content: "\EA5C" }
.icon-user:before { content: "\EA5D" }
.icon-warenkorb:before { content: "\EA5E" }
.icon-weather:before { content: "\EA5F" }
.icon-webcam:before { content: "\EA60" }
.icon-whatsapp:before { content: "\EA61" }
.icon-windwerte:before { content: "\EA62" }
.icon-youtube:before { content: "\EA63" }
.icon-zoom-in:before { content: "\EA64" }


        :root {
        --icon-add: "\EA01";
        --icon-anl-3er-sessellift: "\EA02";
        --icon-anl-4er-sessellift: "\EA03";
        --icon-anl-6er-sessellift: "\EA04";
        --icon-anl-8er-sessellift: "\EA05";
        --icon-anl-bahn: "\EA06";
        --icon-anl-foerderband: "\EA07";
        --icon-anl-gondel: "\EA08";
        --icon-anl-kinderlift: "\EA09";
        --icon-anl-schlepplift: "\EA0A";
        --icon-anl-sessellift: "\EA0B";
        --icon-anlagen: "\EA0C";
        --icon-arrow-back: "\EA0D";
        --icon-arrow-down: "\EA0E";
        --icon-arrow-dropdown: "\EA0F";
        --icon-arrow-left: "\EA10";
        --icon-arrow-next: "\EA11";
        --icon-arrow-right-portal: "\EA12";
        --icon-arrow-right: "\EA13";
        --icon-bahn: "\EA14";
        --icon-bb-ticket: "\EA15";
        --icon-bear: "\EA16";
        --icon-bed: "\EA17";
        --icon-berg-2: "\EA18";
        --icon-calendar-check: "\EA19";
        --icon-calendar-date: "\EA1A";
        --icon-calendar: "\EA1B";
        --icon-checkmark: "\EA1C";
        --icon-clock: "\EA1D";
        --icon-close: "\EA1E";
        --icon-closed: "\EA1F";
        --icon-cross: "\EA20";
        --icon-danger: "\EA21";
        --icon-depot: "\EA22";
        --icon-elements: "\EA23";
        --icon-external-link: "\EA24";
        --icon-facebook: "\EA25";
        --icon-folder: "\EA26";
        --icon-fullscreen: "\EA27";
        --icon-geschenk: "\EA28";
        --icon-gondel: "\EA29";
        --icon-gplus: "\EA2A";
        --icon-guiding: "\EA2B";
        --icon-gutschein: "\EA2C";
        --icon-home: "\EA2D";
        --icon-in-vorbereitung: "\EA2E";
        --icon-information: "\EA2F";
        --icon-instagram: "\EA30";
        --icon-kinderland: "\EA31";
        --icon-kulinarik-2: "\EA32";
        --icon-language: "\EA33";
        --icon-linkedin: "\EA34";
        --icon-locate: "\EA35";
        --icon-location: "\EA36";
        --icon-mail: "\EA37";
        --icon-map-poi: "\EA38";
        --icon-map: "\EA39";
        --icon-menu: "\EA3A";
        --icon-minus: "\EA3B";
        --icon-money: "\EA3C";
        --icon-network-cam: "\EA3D";
        --icon-open: "\EA3E";
        --icon-opened: "\EA3F";
        --icon-overview: "\EA40";
        --icon-package: "\EA41";
        --icon-panorama-cam: "\EA42";
        --icon-pause: "\EA43";
        --icon-personen: "\EA44";
        --icon-phone: "\EA45";
        --icon-plus: "\EA46";
        --icon-poi-map: "\EA47";
        --icon-poi: "\EA48";
        --icon-quote: "\EA49";
        --icon-restaurant-952: "\EA4A";
        --icon-schnee: "\EA4B";
        --icon-shopping-cart: "\EA4C";
        --icon-snowpark: "\EA4D";
        --icon-sort-down: "\EA4E";
        --icon-sort-up: "\EA4F";
        --icon-speed: "\EA50";
        --icon-sportbericht: "\EA51";
        --icon-spotify: "\EA52";
        --icon-star: "\EA53";
        --icon-suche: "\EA54";
        --icon-ticket: "\EA55";
        --icon-ticket2: "\EA56";
        --icon-tickets: "\EA57";
        --icon-tik-tok: "\EA58";
        --icon-timer: "\EA59";
        --icon-tvchannel: "\EA5A";
        --icon-twitter: "\EA5B";
        --icon-unterkunft: "\EA5C";
        --icon-user: "\EA5D";
        --icon-warenkorb: "\EA5E";
        --icon-weather: "\EA5F";
        --icon-webcam: "\EA60";
        --icon-whatsapp: "\EA61";
        --icon-windwerte: "\EA62";
        --icon-youtube: "\EA63";
        --icon-zoom-in: "\EA64";
        

        }